import React, { useState } from 'react';
import { navigate } from 'gatsby';

import Button from '../../common/Button/Button';

import * as cls from './BlogPostTabs.module.scss';

const TABS = { overview: 1, info: 2 };

export default function BlogPostTabs({ content }) {
  const overview = content.querySelector('#overview');
  const info = content.querySelector('#info');
  const imageSrc = content
    .querySelector('#map_image picture img')
    ?.getAttribute('src');

  const [currentTab, setCurrentTab] = useState(TABS.overview);
  const getBtnClass = tab =>
    currentTab === tab ? `${cls.activeTabBtn} ${cls.tabBtn}` : cls.tabBtn;

  const handleClickBack = () => navigate(-1);

  return (
    <section>
      <div className={cls.tabHeader}>
        <div className={cls.tabs}>
          <Button
            mode='menu'
            className={getBtnClass(TABS.overview)}
            onClick={() => setCurrentTab(TABS.overview)}
          >
            {content.querySelector('.overview__header')?.text}
          </Button>
          <Button
            mode='menu'
            className={getBtnClass(TABS.info)}
            onClick={() => setCurrentTab(TABS.info)}
          >
            {content.querySelector('.info__header')?.text}
          </Button>
        </div>
        <Button mode='back' className={cls.backBtn} onClick={handleClickBack}>
          Back
        </Button>
      </div>
      {currentTab === TABS.overview ? (
        <>
          <div dangerouslySetInnerHTML={{ __html: overview }} />
          <img src={imageSrc} alt='map' />
        </>
      ) : (
        <div dangerouslySetInnerHTML={{ __html: info }} />
      )}
    </section>
  );
}

import React from 'react';
import { Helmet } from 'react-helmet';
import { parse } from 'node-html-parser';

import BlogPostTabs from './BlogPostTabs';

import * as cls from './BlogPostPage.module.scss';

export default function BlogPostPage({ post, htmlTitle }) {
  const content = parse(post.content);
  const modelData = content.querySelector('#model-data');
  const images = content.querySelectorAll('.wp-block-image');

  return (
    <>
      <Helmet>
        <title>{`${htmlTitle} - ${post.title}`}</title>
        <meta name='Description' content={post.excerpt} />
      </Helmet>

      <div className={cls.page}>
        <div className={cls.wrapper}>
          <section className={cls.modelData}>
            <div dangerouslySetInnerHTML={{ __html: images[0] }} />
            <h1 className={cls.mainHeader}>{post.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: modelData }} />
          </section>
          <BlogPostTabs content={content} />
        </div>
      </div>
    </>
  );
}
